<template>
    <footer class="footer px-4">
      <b-row align-v="center" class="justify-content-lg-between">
        <b-col lg="6">
          <div class="copyright text-center text-lg-left text-muted">
          <img src="../../assets/img/footer-logo.png"> <br/><br/>
            © {{year}} FINCORE <br/>Promotora de Negocios Vilna S.A.P.I. de C.V. <br/>Avenida Paseo Royal Country #4650 Int 401-E <br/>
            Puerta de Hierro, Zapopan, Jalisco <br/>CP. 45400, México.
          </div>
        </b-col>
        <!-- <b-col lg="6">
          <b-nav align="center" class="nav-footer justify-content-lg-end">
            <b-nav-item href="https://www.creative-tim.com"  target="_blank">
              Creative Tim
            </b-nav-item>
            <b-nav-item href="https://www.creative-tim.com/presentation" target="_blank">
            About Us
            </b-nav-item>
            <b-nav-item href="http://blog.creative-tim.com" target="_blank">
              Blog
            </b-nav-item>
            <b-nav-item href="https://www.creative-tim.com/license" target="_blank">
              License
            </b-nav-item>
          </b-nav>
        </b-col> -->
      </b-row>
    </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
</style>