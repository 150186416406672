/*!

=========================================================
* BootstrapVue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';



// router setup
import router from './routes/router';
import store from './store';
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
// axios.defaults.baseURL = 'https://198.211.108.20:8080/api';
axios.defaults.baseURL = 'https://api.fincore.com.mx/app/api';
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
});