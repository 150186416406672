var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Inicio',
          path: '/dashboard',
          icon: 'icon-inicio',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Clientes',
          path: '/customer',
          icon: 'icon-clientes',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Empresa',
          path: '/company',
          icon: 'icon-empresa',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Inversiones',
          path: '/investment',
          icon: 'icon-inversiones',
        }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Oportunidades',
            path: '/opportunities',
            icon: 'icon-oportunidades'
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Reportes',
              path: '/maps',
              icon: 'icon-reportes'
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Perfil',
              path: '/profile',
              icon: 'icon-perfil'
              }}})],1)],2),_c('div',{staticClass:"main-content navbar-dark main-bg"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer',{staticClass:"navbar-dark footer-bg"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }