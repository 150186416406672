<template>
  <div id="app" >
    <div class="text-center">
      <b-alert
          :show="dismissCountDown"
          dismissible
          :variant="mensaje.color"
          @dismissed="dismissCountDown=0"
          @dismiss-count-down="countDownChanged"
          >
          {{mensaje.texto}}
      </b-alert>
    </div>
  <router-view>    
  </router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EventBus from '@/bus'

export default {
  data(){
    return{
      mensaje: {color: 'danger', texto: 'tendria que mostrar esto'},
      dismissSecs: 5,
      dismissCountDown: 0,
    }
  },
  methods:{
    ...mapActions(['cerrarSesion', 'leerToken']),
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
  },
  computed:{
    ...mapGetters(['estaActivo'])
  },
  created(){
    this.leerToken(),
    EventBus.$on('alerta', (item) => {
      this.mensaje.color = item.color
      this.mensaje.texto = item.texto
      this.showAlert()
    })
  }
}
</script>