<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Inicio',
            path: '/dashboard',
            icon: 'icon-inicio',
          }"
        >
        </sidebar-item>


        <sidebar-item
          :link="{
            name: 'Clientes',
            path: '/customer',
            icon: 'icon-clientes',
          }"
        >
        </sidebar-item>


        <sidebar-item
          :link="{
            name: 'Empresa',
            path: '/company',
            icon: 'icon-empresa',
          }"
        >
        </sidebar-item>


        <sidebar-item
          :link="{
            name: 'Inversiones',
            path: '/investment',
            icon: 'icon-inversiones',
          }"
        >
        </sidebar-item>

        <sidebar-item
            :link="{
              name: 'Oportunidades',
              path: '/opportunities',
              icon: 'icon-oportunidades'
              }"
            >
        </sidebar-item>

        <sidebar-item
              :link="{
                name: 'Reportes',
                path: '/maps',
                icon: 'icon-reportes'
              }">
        </sidebar-item>

        <sidebar-item
              :link="{
                name: 'Perfil',
                path: '/profile',
                icon: 'icon-perfil'
                }">
        </sidebar-item>

        <!-- <sidebar-item
                :link="{
                  name: 'Tables',
                  path: '/tables',
                  icon: 'ni ni-bullet-list-67 text-red'
                }">
        </sidebar-item> -->

        <!-- <sidebar-item
                  :link="{
                    name: 'Login',
                    path: '/login',
                    icon: 'ni ni-key-25 text-info'
                  }">
        </sidebar-item>
        <sidebar-item
                  :link="{
                    name: 'Register',
                    path: '/register',
                    icon: 'ni ni-circle-08 text-pink'
                  }">
        </sidebar-item> -->
      </template>

    </side-bar>
    <div class="main-content navbar-dark main-bg">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer class="navbar-dark footer-bg" v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import DashboardContent from './Content.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      DashboardContent,
      FadeTransition
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style scoped lang="scss">
@import '../../assets/styles/style.css';
</style>
