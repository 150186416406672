import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';

import NotFound from '@/views/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    redirect: 'login',
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Dashboard.vue'),
        meta: {requireAuth: true}
      },
      {
        path: '/investment',
        name: 'inversiones',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Investment.vue'),
        meta: {requireAuth: true}
      },
      {
        path: '/customer',
        name: 'clientes',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Customer.vue'),
        meta: {requireAuth: true}
      },
      {
        path: '/company',
        name: 'empresa',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Company.vue'),
        meta: {requireAuth: true}
      },
      {
        path: '/icons',
        name: 'icons',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Icons.vue'),
        meta: {requireAuth: true}
      },
      {
        path: '/opportunities',
        name: 'opportunities',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Opportunities.vue'),
        meta: {requireAuth: true}
      },
      {
        path: '/profile',
        name: 'perfil',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/UserProfile.vue'),
        meta: {requireAuth: true}
      },
      {
        path: '/maps',
        name: 'maps',
        component: () => import(/* webpackChunkName: "demo" */ '../views/GoogleMaps.vue'),
        meta: {requireAuth: true}
      },
      {
        path: '/tables',
        name: 'tables',
        component: () => import(/* webpackChunkName: "demo" */ '../views/RegularTables.vue'),
        meta: {requireAuth: true}
      }
    ]
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Login.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Register.vue')
      },
      { path: '*', component: NotFound }
    ]
  },
];

export default routes;
